<template>
  <div>
    <div ref="dealDrawer" :class="['deal-drawer', { open: state.isOpen }]" :aria-expanded="state.isOpen">
      <div
        class="drawer-title h5 flex pointer"
        role="button"
        :aria-label="state.isOpen ? 'Close Deals Drawer' : 'Open Deals Drawer'"
        @click.prevent="toggleOpen"
      >
        {{ drawer_header_title }} <ArrowDownSLine />
      </div>
      <div class="deal-items-container flex">
        <a
          v-for="(deal, i) in deals"
          :key="deal.main_text + i"
          class="deal flex-col cntr"
          :href="localePath(deal.link.href)"
          :style="`${deal.background_color ? `background-color: ${deal.background_color};` : ''}${
            deal.text_color ? `color: ${deal.text_color};` : ''
          }`"
        >
          <CloudinaryImage
            v-if="deal?.image?.public_id"
            :public-id="deal?.image?.public_id"
            :options="{ width: 300, height: 200, quality: 'auto:eco' }"
            :alt="deal.deal_name"
          />
          <template v-else>
            <div v-if="deal.deal_name" class="h5 deal-name">{{ deal.deal_name }}</div>
            <div v-if="deal.main_text" class="h4 deal-main">{{ deal.main_text }}</div>
            <div v-if="deal.extra_value_prop" class="value-prop">{{ deal.extra_value_prop }}</div>
          </template>
        </a>
      </div>
    </div>
    <div class="deal-drawer-overlay" />
  </div>
</template>

<script setup lang="ts">
import { addBodyClass, removeBodyClass, handleOutsideClick } from '~/util/eventHandler';

const localePath = useLocalePath();
const dealDrawer = ref<HTMLElement | null>(null);

defineProps({
  deals: {
    type: Array as () => any[],
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  drawer_header_title: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
});

const state = reactive({
  isOpen: false as boolean,
});

onMounted(() => {
  document.addEventListener('click', (e: any) => {
    const isOutside = handleOutsideClick(e, dealDrawer.value as HTMLElement, 'open');
    if (isOutside && state.isOpen) toggleOpen();
  });
});
onUnmounted(() => {
  document.removeEventListener('click', (e: any) => {
    const isOutside = handleOutsideClick(e, dealDrawer.value as HTMLElement, 'open');
    if (isOutside && state.isOpen) toggleOpen();
  });
});
function toggleOpen(): void {
  state.isOpen = !state.isOpen;
  if (state.isOpen) {
    addBodyClass('deal-drawer-open');
  } else {
    removeBodyClass('deal-drawer-open');
  }
}
</script>

<style lang="scss">
.deal-drawer-overlay {
  display: none;
}
body.deal-drawer-open {
  .deal-drawer-overlay {
    display: block;
    background-color: $color-neutral-black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: map.get(local-vars.$zindex, 'modal-overlay');
  }
}

.footer-bottom {
  padding-bottom: 65px !important;
}
</style>

<style lang="scss" scoped>
$animationDuration: 0.25s;
.deal-drawer {
  z-index: map.get(local-vars.$zindex, 'page');
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  .deal-items-container {
    max-height: 0;
    padding: 0;
  }
  &.open {
    z-index: map.get(local-vars.$zindex, 'modal-window');
    .deal-items-container {
      max-height: 300px;
      padding: 2.5rem 2rem;
    }
    .drawer-title :deep(svg) {
      transform: rotate(0deg);
    }
  }
}
.drawer-title {
  color: $color-neutral-white;
  background-color: $color-neutral-cool-900;
  justify-content: center;
  padding: #{local-functions.rem-calc(20 25)};
  margin: 0 auto;
  width: fit-content;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  :deep(svg) {
    transition: transform $animationDuration ease;
    fill: $color-neutral-white;
    transform: rotate(180deg);
  }
  @include local-mixins.tablet_and_mobile {
    width: 100%;
  }
}
.deal-items-container {
  transition: max-height $animationDuration ease;
  background-color: $color-neutral-white;
  gap: 2rem;
  overflow-x: auto;
  overflow-y: hidden;
  @include local-mixins.desktop {
    border-top: 8px solid $color-neutral-cool-900;
  }
  @include local-mixins.tablet_and_mobile {
    gap: 1.5rem;
  }
}
.deal {
  padding: #{local-functions.rem-calc(22 50)};
  min-width: 300px;
  width: 300px;
  height: 200px;
  gap: 1rem;
  text-align: center;
}
.deal-main,
.deal-name,
.value-prop {
  margin: 0;
}
.value-prop {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 500;
}
</style>
